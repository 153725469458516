import { Toast } from 'vant'
import { mapState } from 'vuex'
// import { cloneDeep as clone } from 'lodash'
import CustomStepper from '../../../components/common/custom-stepper/index.vue'
import { StockType } from '@/constants/common'
import { isDef } from '@/utils/common'
import EmptyList from '@/components/common/empty-list/index.vue'

export default {
    name: 'product-list',
    components: {
        CustomStepper,
        EmptyList,
    },
    computed: {
        ...mapState({
            user: status => status.user.user,
            products: state => state.product.products,
            brandId: state => state.global.brandId,
            userId: state => state.user.user.id,
        }),

        minstep() {
            return this.product.minstep && this.product.minstep[this.product.userRank] || 1;
        },

        minusStep() {
            if (this.product.decMinStep) {
                if (!Object.keys(this.product.decMinStep || {}).length) {
                    return this.minstep
                }
            }
            return this.product.decMinStep[this.product.userRank] || 1
        },
    },
    data() {
        return {
            product1: require('./../../../assets/images/qingbuluo-product-list/p.png'),
            product2: require('./../../../assets/images/qingbuluo-product-list/p2.png'),
            logo: require('./../../../assets/images/qingbuluo-product-list/logo@2x.png'),
            slogan: require('./../../../assets/images/qingbuluo-product-list/beauty-life@2x.png'),
            functionList: [
                { img: require('./../../../assets/images/qingbuluo-product-list/slim.png'), name: '减肥塑形' },
                { img: require('./../../../assets/images/qingbuluo-product-list/fire.png'), name: '快速供能' },
                { img: require('./../../../assets/images/qingbuluo-product-list/xin.png'), name: '营养健康' },
            ],
            product: { formats: [{ count: 0 }] }
        }
    },
    methods: {
        /**
         * 初始化页面数据
         */
        async init() {
            const { brandId, userId } = this

            await this.$store.dispatch('product/getProducts', {
                brandId, userId
            })

            this.product = this.products[0]

            //  获取限购的商品数量
            this.$store.dispatch('product/getLimitProductCount', {
                brandId, userId
            })
        },

        // 加入[购物车]
        async addToCart() {
            await this.$store.dispatch('cart/addToCart', {
                type: StockType.Sell,
                brandId: this.brandId,
                product: this.getWrappedProduct()
            })
            this.$emit('after-cart', this.product.id)
            Toast('加入购物车成功~')
        },

        // 封装 product 结构（立即购买/加入购物车）
        getWrappedProduct() {
            return {
                ...this.product,
                formats: { [this.product.formats[0].id]: this.product.formats[0] },
                count: this.product.formats[0].count,
                levelId: this.product.levelId,//需要把levelid添加进去
            }
        },

        onOverLimitProduct() {
            // Toast('超出最大购买量或者最小购买量~')
        }
    },

    created() {
        this.init()
    },
}